import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import {
  Document,
  Member,
  ProgressIterable,
  ProgressUpdate,
  getWorkspaceStateFromString,
  getWorkspaceTypeFromString,
  Workspace,
  WorkspaceAccess,
  WorkspaceDocument,
  WorkspaceInclude,
  WorkspaceType,
  WorkspaceInfo,
} from '@core/models';
import { AdminApiService } from './admin-api.service';
import { DocumentTypes, LevelPolicy } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { MemberService } from './member.service';
import jsonpatch from 'json-patch';
import { IdentityService } from './identity.service';
import { environment } from '@env';
import '@core/date-extensions';

export class CreateWorkspaceResult {
  constructor(public workspace: Workspace) { }
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  constructor(
    private readonly adminApiService: AdminApiService,
    private readonly identityService: IdentityService,
    private readonly documentApiService: DocumentApiService,
    private readonly memberService: MemberService,
  ) { }

  /**
   * Convert a workspace to a workspace document
   * @param workspace workspace to convert
   * @returns workspace document
   */
  convertWorkspaceToWorkspaceDocument(workspace: Workspace): WorkspaceDocument {
    return {
      id: workspace.workspaceId,
      documentType: DocumentTypes.workspace,
      content: {
        displayName: workspace.displayName,
        uriName: workspace.uriName,
        state: workspace.state,
        type: workspace.type,
        plan: workspace.plan,
        region: workspace.region,
        timeZoneOffset: workspace.timeZoneOffset,
        clientWorkspaceId: workspace.clientWorkspaceId,
        created: workspace.created?.toISOStringWithTimeZone() ?? null,
        createdBy: workspace.createdBy,
        lastUpdate: workspace.lastUpdate?.toISOStringWithTimeZone() ?? null,
        lastUpdateBy: workspace.lastUpdateBy,
        description: workspace.description,
        workspaceMemberLimit: workspace.workspaceMemberLimit,
        publicWorkspacePolicy: workspace.publicWorkspacePolicy,
        ownerMemberId: workspace.ownerMemberId,
        members: workspace.members.map(m => ({
          id: m.memberId,
          workspacePolicy: m.workspacePolicy
        })),
        tags: workspace.tags,
      },
    };
  }

  /**
   * Convert a workspace document to a workspace
   * @param workspaceDocument workspace document to convert
   * @returns workspace
   */
  convertWorkspaceDocumentToWorkspace(workspaceDocument: WorkspaceDocument): Workspace {
    return {
      workspaceId: workspaceDocument.id,
      displayName: workspaceDocument.content.displayName,
      uriName: workspaceDocument.content.uriName,
      state: getWorkspaceStateFromString(workspaceDocument.content.state),
      type: getWorkspaceTypeFromString(workspaceDocument.content.type),
      plan: workspaceDocument.content.plan,
      region: workspaceDocument.content.region,
      timeZoneOffset: workspaceDocument.content.timeZoneOffset,
      clientWorkspaceId: workspaceDocument.content.clientWorkspaceId,
      created: workspaceDocument.content.created ? new Date(workspaceDocument.content.created) : null,
      createdBy: workspaceDocument.content.createdBy,
      lastUpdate: workspaceDocument.content.lastUpdate ? new Date(workspaceDocument.content.lastUpdate) : null,
      lastUpdateBy: workspaceDocument.content.lastUpdateBy,
      description: workspaceDocument.content.description,
      workspaceMemberLimit: workspaceDocument.content.workspaceMemberLimit || -1,
      publicWorkspacePolicy: workspaceDocument.content.publicWorkspacePolicy || '',
      ownerMemberId: workspaceDocument.content.ownerMemberId || '',
      members: workspaceDocument.content.members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy
      })),
      tags: workspaceDocument.content.tags || [],
    };
  }

  async updateWorkspaceData(workspaceId: string, info: WorkspaceInfo): Promise<void> {
    const document = await this.documentApiService.getWorkspaceData(workspaceId);
    const content = document ? document.content : {};

    content.info = {
      workspaceDisplayName: info.workspaceDisplayName ?? content.info?.workspaceDisplayName ?? '',
      workspaceUrl: info.workspaceUrl ?? content.info?.workspaceUrl ?? '',
      workspacePath: info.workspacePath ?? content.info?.workspacePath ?? '',
      applicationLogoDataUrl: info.applicationLogoDataUrl ?? content.info?.applicationLogoDataUrl ?? '',
      reportLogoDataUrl: info.reportLogoDataUrl ?? content.info?.reportLogoDataUrl ?? '',
      memberLimit: info.memberLimit ?? content.info?.memberLimit ?? -1,
      type: info.type || content.info?.type || '',
      state: info.state || content.info?.state || 'active',
      plan: info.plan || content.info?.plan || 'essentials',
      region: info.region ?? content.info?.region ?? '',
      timeZoneOffset: info.timeZoneOffset || content.info?.timeZoneOffset || '+0:00',
      created: info.created || content.info?.created || null,
      lastUpdated: info.lastUpdated || content.info?.lastUpdated || new Date(2024,1,1).toISOStringWithTimeZone(),
      clientWorkspaceId: info.clientWorkspaceId || content.info?.clientWorkspaceId || '',
    } as WorkspaceInfo;

    await this.documentApiService.updateWorkspaceData(workspaceId, {
      id: document.id,
      documentId: document.documentId,
      documentType: document.documentType,
      content: content
    });
  }

  /**
   * Get a workspace by its owner
   * @param memberId owner member id
   * @returns The first workspace found, or null if the owner does not have a workspace
   */
  async getWorkspaceByOwner(memberId: string): Promise<Workspace | null> {
    const [workspaces, _] = await this.adminApiService
      .getWorkspaces({ where: { "eq": ['ownerMemberId', memberId] } });
    return workspaces.length > 0 ? this.convertWorkspaceDocumentToWorkspace(workspaces[0]) : null;
  }

  /**
   * Create a workspace in the platform
   * @param workspaceToCreate The workspace to create
   * @param members The embers to add to the workspace
   * @returns The created workspace
   */
  async *createWorkspace(
    workspaceToCreate: Workspace,
    members: Member[],
    operations: WorkspaceInclude[] = []): ProgressIterable<CreateWorkspaceResult> {

    yield new ProgressUpdate(0, `Creating workspace`);
    // Create workspace
    const workspace = this.convertWorkspaceDocumentToWorkspace(
      await this.adminApiService.createWorkspace(
        this.convertWorkspaceToWorkspaceDocument(workspaceToCreate)));

    yield new ProgressUpdate(30, 'Refreshing your access');
    await this.identityService.refreshClaims();

    yield new ProgressUpdate(40, 'Preparing workspace');
    const workspaceData = new Document(
      workspace.workspaceId,
      workspace.workspaceId,
      DocumentTypes.workspaceData,
      {
        info: {
          workspaceDisplayName: workspaceToCreate.displayName,
          workspaceUrl: environment.workspaceUrl,
          workspacePath: workspaceToCreate.uriName,
          applicationLogoDataUrl: '',
          reportLogoDataUrl: '',
          memberLimit: workspaceToCreate.workspaceMemberLimit,
          type: workspaceToCreate.type,
          state: 'active',
          plan: workspaceToCreate.plan,
          region: workspaceToCreate.region,
          timeZoneOffset: workspaceToCreate.timeZoneOffset,
          created: new Date().toISOStringWithTimeZone(),
          lastUpdated: new Date().toISOStringWithTimeZone(),
          clientWorkspaceId: workspaceToCreate.clientWorkspaceId,
        } as WorkspaceInfo,
        include: operations,
      },
    );
    await this.documentApiService.createDocument(workspaceData, workspace.workspaceId);

    yield new ProgressUpdate(60, 'Creating new members');
    for await (const result of this.memberService.createMissingMembers(members)) {
      if (result instanceof ProgressUpdate) {
        yield new ProgressUpdate(60 + result.value * 0.3, result.message);
      }
    }

    yield new ProgressUpdate(100, 'Workspace created');
    yield new CreateWorkspaceResult(workspace);
  }

  /**
   * Create the client workspace for an app workspace
   * @param appWorkspaceId The app workspace id
   */
  async *createClientWorkspace(
    appWorkspaceId: string,
    clientWorkspaceId: string): ProgressIterable<CreateWorkspaceResult> {

    const appWorkspace = await this.getWorkspace(appWorkspaceId);
    const clientWorkspace = {
      workspaceId: clientWorkspaceId || uuidv4(),
      displayName: `${appWorkspace.displayName} Client`,
      uriName: `${appWorkspace.uriName}-client`,
      state: appWorkspace.state,
      type: WorkspaceType.client,
      plan: appWorkspace.plan,
      region: appWorkspace.region,
      timeZoneOffset: appWorkspace.timeZoneOffset,
      created: new Date(),
      createdBy: this.identityService.id(),
      lastUpdate: new Date(),
      lastUpdateBy: this.identityService.id(),
      clientWorkspaceId: '',
      description: `Client workspace for ${appWorkspace.displayName}`,
      workspaceMemberLimit: appWorkspace.workspaceMemberLimit,
      publicWorkspacePolicy: appWorkspace.publicWorkspacePolicy,
      ownerMemberId: appWorkspace.ownerMemberId,
      members: appWorkspace.members,
      tags: [],
    };

    yield new ProgressUpdate(0, `Creating client workspace`);
    const workspace = this.convertWorkspaceDocumentToWorkspace(
      await this.adminApiService.createWorkspace(
        this.convertWorkspaceToWorkspaceDocument(clientWorkspace)));

    yield new ProgressUpdate(30, 'Refreshing your access');
    await this.identityService.refreshClaims();

    yield new ProgressUpdate(40, 'Preparing client workspace');
    const workspaceData = new Document(
      clientWorkspace.workspaceId,
      clientWorkspace.workspaceId,
      DocumentTypes.workspaceData,
      {
        info: {
          workspaceDisplayName: clientWorkspace.displayName,
          workspaceUrl: environment.workspaceUrl,
          workspacePath: clientWorkspace.uriName,
          applicationLogoDataUrl: '',
          reportLogoDataUrl: '',
          memberLimit: clientWorkspace.workspaceMemberLimit,
          state: clientWorkspace.state,
          type: clientWorkspace.type,
          plan: clientWorkspace.plan,
          region: clientWorkspace.region,
          timeZoneOffset: clientWorkspace.timeZoneOffset,
          created: new Date().toISOStringWithTimeZone(),
          lastUpdated: new Date().toISOStringWithTimeZone(),
        } as WorkspaceInfo,
        include: [],
      },
    );
    await this.documentApiService.createDocument(workspaceData, clientWorkspace.workspaceId);

    yield new ProgressUpdate(100, 'Client workspace created');
    yield new CreateWorkspaceResult(workspace);
  }

  /**
   * Update the member's access in the specified workspaces
   * @param memberId The member to update in the workspaces
   * @param workspaces The original workspaces
   * @param workspaceAccesses The new workspace accesses for each workspace
   */
  async *updateMemberAccessInWorkspaces(
    memberId: string,
    workspaces: Workspace[], // Original workspaces
    workspaceAccesses: WorkspaceAccess[], // New workspace accesses
  ): ProgressIterable<void> {

    // Remove member access in workspaces
    let progressMultiplier = 50 / workspaces.length;

    for (const workspace of workspaces) {
      const hasAccess = workspaceAccesses.some(wa => wa.workspaceId === workspace.workspaceId);

      if (hasAccess) {
        // Do not remove member access if the member has access
        continue;
      }

      const members = workspace.members
        .filter(m => m.memberId !== memberId)
        .map(m => ({
          id: m.memberId,
          workspacePolicy: m.workspacePolicy,
        }));

      // Validate workspace policy limits
      const updatedWorkspace = { ...workspace };
      updatedWorkspace.members = members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy,
      }));
      const errorMessage = this.validateMemberLimit(updatedWorkspace);
      if (errorMessage) {
        yield new ProgressUpdate(100, `${workspace.displayName}: ${errorMessage}`, false);
        return
      }

      const instructions: jsonpatch.OpPatch[] = [
        {
          op: 'replace', path: '/members', value: members,
        },
      ];

      const progress = 0 + progressMultiplier * workspaces.indexOf(workspace);
      yield new ProgressUpdate(progress, `Removing member access in ${workspace.displayName}`);

      await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
    }

    // Update/Add member access in workspaces
    progressMultiplier = 50 / workspaceAccesses.length;
    for (const workspaceAccess of workspaceAccesses) {
      let workspace = workspaces.find(w => w.workspaceId === workspaceAccess.workspaceId);

      if (!workspace) {
        // The workspace does not exist
        workspace = await this.getWorkspace(workspaceAccess.workspaceId);
      }

      const access = workspace.members.find(m => m.memberId === memberId);

      if (access && access.workspacePolicy === workspaceAccess.workspacePolicy) {
        // Do not update member access if it is the same
        continue;
      }

      const progress = 50 + progressMultiplier * workspaceAccesses.indexOf(workspaceAccess);
      yield new ProgressUpdate(progress, `Updating member access in ${workspace.displayName}`);

      const members = [
        ...workspace.members
          .filter(m => m.memberId !== memberId)
          .map(m => ({
            id: m.memberId,
            workspacePolicy: m.workspacePolicy,
          })),
        {
          id: memberId,
          workspacePolicy: workspaceAccess.workspacePolicy
        },
      ];

      // Validate workspace policy limits
      const updatedWorkspace = { ...workspace };
      updatedWorkspace.members = members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy,
      }));
      const errorMessage = this.validateMemberLimit(updatedWorkspace);
      if (errorMessage) {
        yield new ProgressUpdate(100, `${workspace.displayName}: ${errorMessage}`, false);
        return;
      }

      const instructions: jsonpatch.OpPatch[] = [
        {
          op: 'replace',
          path: '/members',
          value: members,
        },
      ];
      await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
      if (workspace.type === WorkspaceType.app && workspace.clientWorkspaceId) {
        await this.adminApiService.patchWorkspace(instructions, workspace.clientWorkspaceId);
      }
    }
  }

  /**
   * Update the member's access in the specified workspace
   * @param memberId The member to update in the workspace
   * @param workspace The original workspace
   * @param workspacePolicy The new workspace policy to force
   */
  async forceMemberAccessInWorkspace(
    memberId: string,
    workspace: Workspace,
    workspacePolicy: string,
  ): Promise<void> {

    const access = workspace.members.find(m => m.memberId === memberId);

    if (access && access.workspacePolicy === workspacePolicy) {
      // Do not update member access if it is the same
      return;
    }

    const members = [
      ...workspace.members
        .filter(m => m.memberId !== memberId)
        .map(m => ({
          id: m.memberId,
          workspacePolicy: m.workspacePolicy,
        }))];
    if (workspacePolicy !== LevelPolicy.none) {
      members.push(
        {
          id: memberId,
          workspacePolicy: workspacePolicy
        });
    }

    const instructions: jsonpatch.OpPatch[] = [
      {
        op: 'replace',
        path: '/members',
        value: members,
      },
    ];
    await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
  }

  /**
   * Validate the member limit of a workspace
   * @param workspace The workspace to validate
   * @returns An error message if the member limit is exceeded, otherwise null
   */
  validateMemberLimit(workspace: Workspace): string | null {
    if (workspace.type !== WorkspaceType.app
      || workspace.workspaceMemberLimit < 0
      || workspace.members.length <= workspace.workspaceMemberLimit) {
      return null;
    }
    return 'The workspace has the maximum number of members.';
  }

  /**
   * Get all workspaces in the platform
   * @returns All workspaces in the platform
   */
  async getWorkspaces(limit?: number, continuationToken?: string): Promise<[Workspace[], string]> {
    return await this.adminApiService
      .getWorkspaces({ limit, continuationToken })
      .then(([workspaces, continuationToken]) =>
        [workspaces.map((w) => this.convertWorkspaceDocumentToWorkspace(w)), continuationToken]);
  }

  /**
   * Get a workspace in the platform
   * @param workspaceId workspace id
   * @returns The workspace
   */
  async getWorkspace(workspaceId: string): Promise<Workspace> {
    const workspaceDocument = await this.adminApiService.getWorkspace(workspaceId);
    return this.convertWorkspaceDocumentToWorkspace(workspaceDocument);
  }

  /**
   * Get record types in a workspace
   * @param workspaceId workspace id
   * @returns The record types in the workspace
   */
  async getRecordTypes(workspaceId: string): Promise<Document[]> {
    return await this.documentApiService.getAllRecordTypes(workspaceId);
  }

  /**
   * Update a workspace in the platform
   * @param workspace workspace to update
   */
  async updateWorkspace(workspace: Workspace): Promise<Workspace> {
    const workspaceDocument = this.convertWorkspaceToWorkspaceDocument(workspace);
    return this.convertWorkspaceDocumentToWorkspace(
      await this.adminApiService.updateWorkspace(workspaceDocument));
  }

  /**
   * Get all the workspaces that depend on the specified workspace.
   * @param workspaceId The workspace id
   * @returns The workspace ids that depend on the specified workspace and a boolean
   * indicating if at least one workspace could not be retrieved
   */
  async getDependentWorkspaces(workspaceId: string): Promise<[string[], boolean]> {
    const workspaceIds: string[] = [];
    let atLeast = false;
    const userMemberId = this.identityService.id();
    const [workspaces, _] = await this.adminApiService.getWorkspaces();
    for (const workspace of workspaces.filter(w => w.content.state === 'active')) {
      if (workspace.content.members.some(m => m.id == userMemberId && m.workspacePolicy !== '')) {
        try {
          const workspaceData = await this.documentApiService.getWorkspaceData(workspace.id);
          if (workspaceData.content.include && workspaceData.content.include
            .some((i: WorkspaceInclude) => i.id === workspaceId)) {
            workspaceIds.push(workspace.id);
          }
        } catch (e) {
          // Ignore errors
          console.error(`Workspace ${workspace.id}: ${e}`);
          atLeast = true;
        }
      } else {
        atLeast = true;
      }
    }
    return [workspaceIds, atLeast];
  }
}
